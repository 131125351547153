@import "@core/scss/core.scss";
@import "./assets/scss/styles";
@import "./assets/scss/variables/variables";
@import "maincolor";
@import "@core/scss/angular/libs/blockui.component";
@import "@core/scss/angular/libs/toastr.component.scss";

@font-face {
  font-family: "TH Sarabun PSK";
  src: url("~/assets/fonts/THSarabun.ttf") format("truetype");
  /* Path to the regular font file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TH Sarabun PSK";
  src: url("~/assets/fonts/THSarabun-Bold.ttf") format("truetype");
  /* Path to the bold font file */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "TH Sarabun PSK";
  src: url("~/assets/fonts/THSarabun-Italic.ttf") format("truetype");
  /* Path to the italic font file */
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "TH Sarabun PSK";
  src: url("~/assets/fonts/THSarabun-Bold-Italic.ttf") format("truetype");
  /* Path to the bold italic font file */
  font-weight: bold;
  font-style: italic;
}

.skeleton-select {
  width: 100%;
  height: 38px;
  border: 1px solid rgba(161, 161, 161, 0.5);
  border-radius: 5px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.modal .modal-header .close {
  box-shadow: unset;
  background: unset;
  transform: unset;
  padding: 0.8rem 1.4rem;
}

.modal .modal-header .close:hover {
  transform: unset;
}

.modal-open {
  padding-right: 0px !important;
  // overflow: inherit;
}

.main-menu .navbar-header {
  height: 10rem;
  align-items: center;
  display: grid;
}

div.dataTables_wrapper div.dataTables_paginate {
  margin-top: 20px !important;
}

.main-menu .main-menu-content {
  height: calc(100% - 10rem) !important;
  position: relative;
}

.dataTables_length select {
  height: $input-height-sm !important;
  padding: $input-padding-y-sm $input-padding-x-sm !important;
  @include font-size($input-font-size-sm);
  line-height: $input-line-height-sm !important;
  @include border-radius($input-border-radius-sm);
  border: 1px solid $custom-control-border-color !important;
}

.ng2-flatpickr-input.form-control {
  background-color: white;
}

table .dropdown-toggle::after {
  background-image: unset;
}

.primary-color {
  color: $primary !important;
}

.dataTables_scrollBody {
  overflow: inherit !important;
}

.text-end {
  text-align: end !important;
}

.date-form-control .ng2-flatpickr-input {
  display: block;
  width: 100%;
  height: 2.714rem;
  padding: 0.438rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.auto-form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  z-index: 11;
  padding: 10px 0px;
  width: 100%;
  border-top: 1px solid rgba(161, 161, 161, 0.3);
  position: sticky;
  bottom: 0;
}

@media print {
  .export-header-qo {
    display: none !important;
  }

  .content-header {
    display: none;
  }
}

.barcode svg {
  width: 100% !important;
}

#address-step-modern .add-bill {
  box-shadow: none !important;
}

@media (max-width: 992px) {
  .bs-stepper .bs-stepper-header {
    flex-direction: row !important;
    justify-content: space-between;
  }
}

table.dataTable td,
table.dataTable th {
  padding: 0.72rem 0rem !important;
}

.note-modal-content {
  font-family: "Prompt", "Montserrat", Helvetica, Arial, serif !important;
}

.note-modal-footer {
  margin-bottom: 2rem;
  padding: 0rem 2rem !important;
  // padding-inline: 2rem !important;
}

.note-modal-body .checkbox input {
  margin-right: 1rem;
}

.note-modal {
  animation: ease-in-out 0.3s modal-fade;
}

// .main-menu.menu-light .navigation > li ul li ul a {
//  padding: 10px 15px 10px 35px;
// }

.my-actions {
  margin: 0 2em;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.right-gap {
  margin-right: auto;
}

.dataTables_empty {
  display: none;
}

.skeleton-loading {
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  padding: 10px;
  margin-inline: 10px;
  margin-block: 10px;
  border-radius: 10px;

  animation: shimmer 5s infinite;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );

    content: "";
  }
}

@keyframes shimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}

.ng-dropdown-panel.ng-select-bottom {
  margin-left: -2px;
  width: 101%;
}

table.dataTable thead > tr {
  th.sorting:before,
  th.sorting_asc:before,
  th.sorting_desc:before,
  th.sorting_asc_disabled:before,
  th.sorting_desc_disabled:before,
  td.sorting:before,
  td.sorting_asc:before,
  td.sorting_desc:before,
  td.sorting_asc_disabled:before,
  td.sorting_desc_disabled:before,
  th.sorting::after,
  th.sorting_asc:after,
  th.sorting_desc:after,
  th.sorting_asc_disabled:after,
  th.sorting_desc_disabled:after,
  td.sorting:after,
  td.sorting_asc:after,
  td.sorting_desc:after,
  td.sorting_asc_disabled:after,
  td.sorting_desc_disabled:after {
    content: "" !important;
    scale: 150%;
  }
}

.form-select:focus {
  border: 1px solid $primary !important;
  outline: 0;
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
}

.custom-checkbox-datatable {
  padding-left: 0rem !important;
  input[type="checkbox"] {
    left: auto !important;
  }
  label {
    position: relative !important;
  }
}

.general-title {
  border-bottom: 1px solid $primary;
  padding-bottom: 0.5rem;
}

.sidebar-nav-custom {
  width: 250px;
  border-right: 1px solid grey;
  padding-right: 1.5rem;
  border-radius: 0rem;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #efefef !important;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cdk-drag-preview input {
  border: none;
}
.cdk-drag-preview textarea {
  padding-top: 10px;
  width: 230px;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-box:last-child {
  border: none;
}

.cdk-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  background: #f0f0f0;
  border: dotted 1px #999;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-handle {
  cursor: move;
}

.dragging {
  position: absolute;
  pointer-events: none;
}

.btn {
  border-radius: 1.5rem;
}

.lang-switcher-sidebar {
  background: #fff;
  color: #fff !important;
  display: block !important;
  box-shadow: -3px 0 8px rgba(34, 41, 47, 0.1);
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  position: fixed;
  top: 45%;
  right: 0;
  padding: 0.5rem;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  z-index: 9999;
}

hr.hr-primary {
  border-top: 1px solid $primary !important;
}

.range-datepicker {
  .calendar-datepicker-icon {
    padding-inline: 1rem;
    background-color: $primary;
    padding-block: 0.6rem;
    border-radius: 5px 0px 0px 5px;
    color: white;
    height: 40px;
  }

  input.form-control {
    border-radius: 0px 5px 5px 0px !important;
    height: 40px;
  }
  .form-control {
    border-radius: 0px 5px 5px 0px !important;
    height: 40px;
  }
}

.apexcharts-text {
  font-family: "Prompt", "Montserrat", Helvetica, Arial, serif !important;
}

.datepicker-custom {
  border: 1px solid #d8d6de;
  border-radius: 10px;
  padding: 5px;
  display: flex;

  .calendar-datepicker-icon {
    padding-inline: 1rem;
    background-color: #e6f1ff;
    padding-block: 0.6rem;
    border-radius: 5px 0px 0px 5px;
    color: #19468d;
    height: 40px;
  }

  .ng2-flatpickr-input-container {
    .form-control {
      border: none !important;
    }
  }
}

@media only screen and (min-width: 992px) {
  .show-pc {
    display: unset;
  }

  .show-m {
    display: none;
  }

  .show-pc-flex {
    display: flex;
  }

  .show-m-flex {
    display: none !important;
  }
}

/* ---- SHOW-M ---- */
@media only screen and (max-width: 991px) {
  .show-pc {
    display: none;
  }

  .show-m {
    display: block;
  }

  .show-pc-flex {
    display: none !important;
  }

  .show-m-flex {
    display: flex;
  }
}

.modal-footer {
  justify-content: space-between !important;
}


input.form-control.flat-picker.flatpickr-input[readonly]{
  background-color: white !important;
}

select option:disabled {
  color: #afafaf !important;
}

.disabled-form .ng2-flatpickr-input.flatpickr-input {
  background-color: #efefef !important;
}

.modal-xl {
  margin-left: auto !important;
  margin-right: auto !important;
}